<template>
  <section class="section container">
    <h1>{{ course.name }}</h1>
    <div class="columns">
      <div class="column">
        <div class="video" v-if="course.videoDescription">
          <vimeo-player
            :videoId="course.videoDescription"
            class="player"
            ref="player"
          />
        </div>
      </div>
      <div class="column">
        <div>
          <div class="level buy-sec">
            <h1>Detalhes</h1>
            <div class="level">
              <div>
                <div class="icon-field">
                  <b-icon
                    custom-class="icon"
                    icon="check"
                    size="is-small"
                  ></b-icon>
                  <span class="span">{{ course.hourlyLoad }} Horas</span>
                </div>

                <div class="icon-field">
                  <b-icon
                    custom-class="icon"
                    icon="check"
                    size="is-small"
                  ></b-icon>
                  <span class="span">{{ course.modulesAmount }} Módulos</span>
                </div>

                <div class="icon-field">
                  <b-icon
                    custom-class="icon"
                    icon="check"
                    size="is-small"
                  ></b-icon>
                  <span class="span">{{ course.videosAmount }} Vídeos</span>
                </div>
              </div>
              <div>
                <div class="icon-field">
                  <b-icon
                    custom-class="icon"
                    icon="check"
                    size="is-small"
                  ></b-icon>
                  <span class="span"
                    >{{ course.lessonsAmount }} Exercícios</span
                  >
                </div>

                <div class="icon-field">
                  <b-icon
                    custom-class="icon"
                    icon="check"
                    size="is-small"
                  ></b-icon>
                  <span class="span"
                    >{{ course.docs ? course.docs.length : 0 }} Apostilas</span
                  >
                </div>

                <div class="icon-field">
                  <b-icon
                    custom-class="icon"
                    icon="check"
                    size="is-small"
                  ></b-icon>
                  <span class="span">Nível {{ course.level }}</span>
                </div>
              </div>
            </div>

            <div v-if="!isAuth() || available" class="buy" @click="verify">
              <p>
                Valor:
                {{
                  course.price.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                }}
              </p>
              <img src="@/assets/img/cartao.png" alt="cartao" />
              <span class="span">Comprar Curso</span>
            </div>
            <router-link v-else to="/user/cursos" tag="div" class="buy">
              <img
                src="@/assets/img/courseIcon.png"
                class="img-perso"
                alt="cartao"
              />
              <span class="span">Disponivel em meus cursos</span>
            </router-link>
          </div>
          <div class="alvo">
            <h2>Público alvo</h2>
            <p>{{ course.targetAudience }}</p>
          </div>
        </div>
      </div>
    </div>

    <p class="descri">{{ course.description }}</p>

    <div class="columns">
      <div class="column">
        <div class="columns">
          <div class="column">
            <div
              v-for="(m, index) in course.modules"
              v-show="index <= course.modules.length / 2"
              :key="m._id"
            >
              <b>{{ m.name }}</b>
              <p v-show="m.description">{{ m.description }}</p>
              <div class="is-flex m-t-sm" v-for="l in m.lessons" :key="l._id">
                <b-icon icon="check" size="is-small"></b-icon>
                <p class="m-l-sm">{{ l.name }}</p>
              </div>
              <hr />
            </div>
          </div>
          <div class="column">
            <div
              v-for="(m, index) in course.modules"
              v-show="index > course.modules.length / 2"
              :key="m._id"
            >
              <b>{{ m.name }}</b>
              <p v-show="m.description">{{ m.description }}</p>
              <div class="is-flex m-t-sm" v-for="l in m.lessons" :key="l._id">
                <b-icon icon="check" size="is-small"></b-icon>
                <p class="m-l-sm">{{ l.name }}</p>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div class="column" v-if="course && course.instructor">
        <div class="instrutor box">
          <div>
            <img :src="course.instructor.image" alt="imagem instrutor" />
            <span>{{ course.instructor.name }}</span>
          </div>
          <p>{{ course.instructor.extra.description }}</p>
        </div>
      </div>
    </div>

    <b-modal :active.sync="modal" :width="740" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <form v-on:submit.prevent="update">
              <b-field label="Data de Nascimento">
                <b-datepicker
                  :month-names="datePicker[0]"
                  :day-names="datePicker[1]"
                  icon="calendar-alt"
                  v-model="user.birthday"
                ></b-datepicker>
              </b-field>

              <b-field label="Celular">
                <b-input
                  type="text"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                  v-model="user.cellPhone"
                  required
                ></b-input>
              </b-field>

              <b-field label="CEP" :type="erroForm">
                <b-input
                  type="text"
                  v-mask="['#####-###']"
                  v-model="cep"
                  maxlength="9"
                  required
                ></b-input>
              </b-field>

              <div class="is-flex-desktop">
                <b-field label="ESTADO" class="m-r-md" v-if="user.state">
                  <b-input type="text" v-model="user.state" required></b-input>
                </b-field>

                <b-field label="CIDADE">
                  <b-input type="text" v-model="user.city" required></b-input>
                </b-field>
              </div>

              <b-field label="ENDEREÇO">
                <b-input type="text" v-model="user.address" required></b-input>
              </b-field>

              <b-field label="BAIRRO">
                <b-input
                  type="text"
                  v-model="user.neighborhood"
                  required
                ></b-input>
              </b-field>

              <b-field label="NÚMERO">
                <b-input
                  type="number"
                  v-model="user.addressNumber"
                  required
                ></b-input>
              </b-field>

              <button class="button is-info m-t-md">Salvar</button>
            </form>
          </div>
        </div>
      </div>
    </b-modal>

    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>
    <p v-show="isLoading" class="boleto">Gerando Boleto</p>
  </section>
</template>

<script>
import Axios from 'axios';
import { mask } from 'vue-the-mask';

// import "../../../assets/js/pagseguro.lightbox";
import '../../../assets/js/checkout';
import { isAuth } from '@/global/auth';

const baseUrl = process.env.VUE_APP_BASE_URL;
const key = process.env.VUE_APP_KEY;



export default {
  directives: { mask },
  data() {
    return {
      course: [],
      modules: [],
      instructor: {
        extra: {
          description: '',
        },
      },
      user: [],
      pag: '',
      available: false,
      enroll: null,
      erroForm: '',
      cep: null,
      modal: false,
      isLoading: false,
      datePicker: [
        [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro',
        ],
        ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      ],
      isAuth,
    };
  },
  methods: {
    verify() {
      if (!isAuth()) {
        this.$buefy.dialog.alert({
          title: 'OPPS!',
          message:
            'Você deve estar logado para comprar um curso! Por favor, faça o login!',
          type: 'is-danger',
          hasIcon: true,
          iconPack: 'fa',
        });

        return this.$router.push({ path: '/login' });
      }

      let temp = true;

      if (this.user.birthday) {
        this.user.birthday = new Date(this.user.birthday);
      }

      if (!this.user.state) temp = false;
      if (!this.user.city) temp = false;
      if (!this.user.zipCode) temp = false;
      if (!this.user.address) temp = false;
      if (!this.user.neighborhood) temp = false;
      if (!this.user.addressNumber) temp = false;
      if (!this.user.phone && !this.user.cellPhone) temp = false;
      if (!this.user.birthday) temp = false;

      if (!temp) {
        this.$buefy.dialog.alert({
          title: 'OPPS!',
          message:
            'Você deve ter endereço, data de nascimento e celular ou telefone preenchido para realizar uma compra!',
          type: 'is-danger',
          hasIcon: true,
          iconPack: 'fa',
        });

        return (this.modal = true);
      }

      this.buy();
    },
    async getCourse() {
      try {
        const data = await Axios.get(
          `/v1/courses/info?_id=${this.$route.params.name}`
        );

        this.course = data.data.data[0];
        return data;
      } catch (error) {
        console.error(error);
      }
    },
    async getModulos() {
      try {
        const data = await Axios.get(
          `/v1/courses/info?_id=${this.$route.params.name}`
        );
        this.modules = data.data;
        return data;
      } catch (error) {
        console.error(error);
      }
    },
    async getAll() {
      await this.getCourse();
      // await this.getModulos();
    },
    async deleteEnroll() {
      try {
        await Axios.delete(`/v1/enrolls/me/course/${this.enroll.data._id}`);
      } catch (err) {
        console.error(err);
      }
    },
    prev() {
      this.$buefy.dialog.alert({
        title: 'OPA!',
        message: 'Entre em contato conosco!',
      });
    },
    async buy() {
      let birthday = new Date(this.user.birthday);
      birthday = birthday.toISOString();
      birthday = birthday.split('T')[0];

      let dados = {
        course: this.course._id,
        user: this.user._id,
        paymentMethod: 'pagarme',
      };

      try {
        this.enroll = await Axios.post(`/v1/enrolls/checkout`, dados);
      } catch (err) {
        return console.error(err);
      }
      /* eslint-disable-next-line */
      var checkout = new PagarMeCheckout.Checkout({
        encryption_key: key,
        success: async (data) => {
          if (data.payment_method !== 'boleto')
            return this.$router.push({ path: '/user/enroll' });

          try {
            this.isLoading = true;
            setTimeout(async () => {
              const enrollMe = await Axios.get(`/v1/enrolls/me`);
              const link = enrollMe.data.filter(
                (i) => i._id === this.enroll.data._id
              );

              this.isLoading = false;

              window.open(link[0].extra.boletoUrl, '_blank');
              this.$router.push({ path: '/user/enroll' });
            }, 10 * 300);
          } catch (err) {
            return console.error(err);
          }
        },
        error: (err) => {
          console.error(err);
          this.deleteEnroll();
        },
        close: () => {
          this.deleteEnroll();
          console.log('The modal has been closed.');
        },
      });

      checkout.open({
        amount: this.course.price * 100,
        buttonText: 'Pagar',
        buttonClass: 'botao-pagamento',
        customerData: 'false',
        createToken: 'true',
        postbackUrl: `${baseUrl}/v1/pagarme`,
        customer: {
          external_id: this.user._id,
          name: this.user.name,
          type: 'individual',
          country: 'br',
          email: this.user.email,
          documents: [
            {
              type: 'cpf',
              number: this.user.cpf,
            },
          ],
          phone_numbers: [
            `+55${
              this.user.phone
                ? this.toNumber(this.user.phone)
                : this.toNumber(this.user.cellPhone)
            }`,
          ],
          birthday,
        },
        billing: {
          name: this.user.name,
          address: {
            country: 'br',
            state: this.user.state,
            city: this.user.city,
            neighborhood: this.user.neighborhood,
            street: this.user.address,
            street_number: this.user.addressNumber,
            zipcode: this.user.zipCode,
          },
        },
        shipping: {
          name: this.user.name,
          fee: 12345,
          delivery_date: '2017-12-25',
          expedited: true,
          address: {
            country: 'br',
            state: this.user.state,
            city: this.user.city,
            neighborhood: this.user.neighborhood,
            street: this.user.address,
            street_number: this.user.addressNumber,
            zipcode: this.user.zipCode,
          },
        },
        items: [
          {
            id: this.enroll.data._id,
            title: this.course.name,
            unit_price: this.course.price * 100,
            quantity: 1,
            tangible: false,
          },
        ],
      });
    },
    getMe() {
      Axios.get('/v1/users/me')
        .then((data) => {
          if (data.data) {
            this.user = data.data;
            this.cep = this.user.zipCode;
          }
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
    getEnroll() {
      Axios.get(`/v1/enrolls/me/available/course/${this.$route.params.name}`)
        .then((data) => {
          this.available = data.data.available;
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
    getCep() {
      var temp = Axios.defaults.headers;
      delete Axios.defaults.headers;

      Axios({
        method: 'GET',
        url: `https://viacep.com.br/ws/${this.cep}/json/`,
      })
        .then((data) => {
          var address = data.data;
          this.user.zipCode = this.toNumber(address.cep);
          this.user.address = address.logradouro;
          this.user.city = address.localidade;
          this.user.state = address.uf;
          this.erroForm = null;
          this.user.neighborhood = address.bairro;
          Axios.defaults.headers = temp;
        })
        .catch((erro) => {
          console.error(erro);
          this.erroForm = 'is-danger';
          this.$buefy.dialog.alert('CEP inválido!');
          Axios.defaults.headers.common['Authorization'] = temp[0];
          Axios.defaults.headers.post['Content-Type'] = temp[1];
          Axios.defaults.headers.common['cache-control'] = temp[2];
        });
    },
    update() {
      delete this.user.createdAt;
      delete this.user.updatedAt;
      delete this.user.roles;

      this.user.addressNumber = this.user.addressNumber * 1;

      Axios.put(`/v1/users/me`, this.user)
        .then((data) => {
          if (data.data) {
            this.modal = false;
            this.user.birthday = new Date(this.user.birthday);
            this.buy();
          }
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
    toNumber(data) {
      return data.replace(/[^\d]+/g, '');
    },
  },
  watch: {
    cep(cep) {
      if (cep.length == 9) {
        this.getCep();
      }
    },
  },
  mounted() {
    this.getAll();

    if (!isAuth()) return;

    this.getMe();
    this.getEnroll();
  },
};
</script>

<style lang="scss" scoped>
.mod {
  display: flex;
  flex-wrap: wrap;
  & > div {
    width: 50%;
  }
}
.instrutor {
  margin-top: 20px;

  div {
    display: flex;
    align-items: center;

    img {
      border: #ccc 0.1rem solid;
      border-radius: 50%;
      margin-bottom: 1rem;
      margin-right: 1rem;
    }
  }
}
.icon-field {
  position: relative;

  .icon {
    position: absolute;
    top: 4px;
  }

  .span {
    margin-left: 23px;
    margin-right: 3rem;
  }
}
h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}
h2 {
  font-size: 1.5rem;
}
.buy-sec {
  position: relative;
  padding: 10px 20px;
  padding-top: 50px;

  & > div {
    & > div {
      padding-top: 20px;
    }
  }

  h1 {
    position: absolute;
    top: 10px;
  }
  background: #eeeeee; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #eeeeee 50%,
    #cccccc 50%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #eeeeee 50%,
    #cccccc 50%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #eeeeee 50%,
    #cccccc 50%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#cccccc',GradientType=1 ); /* IE6-9 */

  .buy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 200px;
    margin-top: -20px;
    cursor: pointer;

    p {
      font-size: 17px;
      margin-left: -5px;
      font-weight: bold;
    }
  }
}
.alvo {
  background: #eeeeee;
  padding: 10px 20px;
}
.descri {
  background: rgb(236, 236, 236);
  margin-bottom: 1rem;
  padding: 10px;
}
</style>
<style lang="scss">
.video {
  padding: 10px;

  .player {
    width: 100%;
    height: 340px;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.img-perso {
  width: 100px;
  height: 100px;
}

.boleto {
  z-index: 9999;
  position: fixed;
  font-size: 50px;
  top: 30%;
  left: calc(50% - 170px);
  color: black;
}
</style>
